'use strict';

/*!
 * Google Fonts
 */
var WebFontConfig = {
    google: {
        families: [
            "Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic:latin"
        ]
    }
};

(function() {
    var wf = document.createElement("script");
    wf.src = ("https:" === document.location.protocol ? "https" : "http") + "://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js";
    wf.type = "text/javascript";
    wf.async = "true";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(wf, s);
})();

/*!
 * jQuery
 */
(function($) {
    Drupal.behaviors.nhb2015 = {
        attach: function(context, settings) {

            // jPanel.
            var jPM = $.jPanelMenu({
                menu: '#navWrapper',
                trigger: '#mobileNav > a',
                animated: true,
                duration: 200
            });

            // Mediacheck.
            mediaCheck({
                media: '(max-width: 767px)',
                entry: function() {
                    jPM.on();
                    $('#mobileNav').fadeIn();
                    $('#navRow').hide();
                },
                exit: function() {
                    jPM.off();
                    $('#mobileNav').fadeOut();
                    $('#navRow').show();
                }
            });

            // PDF and Dialog Links
            function linkIconAdd(element, icon){
              $(element).each(function(index, el) {
                $(this).addClass("iconLink").after(icon);
              });
            }

            // Remove PDF and Dialog Links
            function linkIconRemove(element){
              $(element).each(function(index, el) {
                $(this).removeClass("iconLink").siblings().remove(".fa");
              });
            }

            // Remove Image Titles
            function imgTitleRemove(){
              $(".imgtitle").each(function(index, el) {
                $(this).remove(".imgtitle");
              });
            }

            // Image Titles
            // Also add Alt Text
            function imgTitleAdd(){
              $("img.contentimg").each(function(index, el) {
                var imgtitle = $(this).attr("title");
                var imgwidth = $(this).attr("width");
                if (imgtitle !== undefined) {
                  // console.log(imgwidth + '\n' + imgtitle);
                  $(el).after( "<span class='imgtitle' style='width:" + imgwidth + "px'>" + imgtitle + "</span>" ).css({'width':imgwidth});
                }
              });
            }
            // PDF Links.
            linkIconAdd("a[href$='.pdf']", "<i class='fa fa-file-pdf-o fa-fw'></i>");
            // Dialog Links
            linkIconAdd("a.empflinkdialog", "<i class='fa fa-plus-square-o fa-fw'></i>");
            // Image Titles
            imgTitleAdd();

            // Dirty fix
            // Colorbox doubles links if they are wrapped inside the cbox link,
            // so we remove them on cbox_complete and rebuild all again.
            // http://www.jacklmoore.com/colorbox/
            $(document).bind("cbox_complete", function(){
              linkIconRemove("a.empflinkdialog");
              linkIconRemove("a[href$='.pdf']");
              // imgTitleRemove();
              // Dialog Links
              linkIconAdd("a.empflinkdialog", "<i class='fa fa-plus-square-o fa-fw'></i>");
              // PDF Links.
              linkIconAdd("a[href$='.pdf']", "<i class='fa fa-file-pdf-o fa-fw'></i>");
              // Image Titles
              // imgTitleAdd();
            });

            $(document).bind("cbox_closed", function(){
              // $.colorbox.remove();
              imgTitleRemove();
              // Image Titles
              imgTitleAdd();
            });
        }
    };
})(jQuery);

